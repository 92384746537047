import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, CanActivateFn } from '@angular/router';
import { Observable, of } from 'rxjs';
import { combineLatestWith, map, switchMap } from 'rxjs/operators';
import { AuthService, STORAGE_KEY_TENANT_ID } from '../services/auth.service';
import { APP_BASE_HREF } from '@angular/common';
import { StorageObject } from '../models/storage.interfaces';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import { STORAGE_OBJECT, URL_TENANT_ID, WINDOW_OBJECT } from '../../injection-tokens/injection-tokens';

export const authGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router = inject(Router);
  const auth = inject(AuthService);
  const appConfig = inject<AppConfig>(APP_CONFIG);
  const baseHref = inject(APP_BASE_HREF);
  const urlTenantId = inject(URL_TENANT_ID) as string;
  const storage = inject(STORAGE_OBJECT) as StorageObject;
  const window = inject(WINDOW_OBJECT) as Window;

  const redirectToUnauthorizedPage = () => {
    router.navigateByUrl('/app-access-unauthorised', { skipLocationChange: true });
  };

  const redirectToTenantSwitchPage = () => {
    router.navigate(['/app-tenant-switch'], {
      skipLocationChange: true,
      queryParams: { redirectUrl: `${window.location.pathname}${window.location.search}` },
    });
  };

  const getSelectedTenantOrgId = (): string | null => {
    const urlTenant = appConfig.tenants.find((availableTenant) => availableTenant.id === urlTenantId);

    if (urlTenant) {
      return urlTenant.id;
    }

    const storageTenant = appConfig.tenants.find(
      (availableTenant) => availableTenant.id === storage.localStore.getItem(STORAGE_KEY_TENANT_ID),
    );

    if (storageTenant) {
      return storageTenant.id;
    }

    return null;
  };

  return auth.isAuthenticated$.pipe(
    switchMap((loggedIn) => {
      if (!loggedIn) {
        auth.login({ redirectPath: state.url, organisationId: getSelectedTenantOrgId() });
        return of(false);
      }
      return auth.getActiveTenant$().pipe(
        combineLatestWith(auth.hasRole('User'), auth.getTenants$()),
        map(([tenantId, hasUserRole, allowedTenants]) => {
          if (!hasUserRole || tenantId == undefined) {
            redirectToUnauthorizedPage();
            return false;
          }

          if (baseHref === '/') {
            window.location.href = `/t/${tenantId}` + state.url;
            return false;
          } else if (urlTenantId !== tenantId) {
            const urlTenant = allowedTenants.find((tenantOption) => tenantOption.id === urlTenantId);
            if (urlTenant !== undefined) {
              redirectToTenantSwitchPage();
              return false;
            } else {
              redirectToUnauthorizedPage();
              return false;
            }
          }

          storage.localStore.setItem(STORAGE_KEY_TENANT_ID, tenantId);

          return true;
        }),
      );
    }),
  );
};
